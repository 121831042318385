.ant-layout .ant-layout-header.sticky-header {
  top: 0;
  height: auto;
  position: sticky;
  /* line-height: 90px; */
  background: white;
  padding-inline: 20px;
  /* border-bottom: 1px solid rgb(0 0 0 / 10%); */
}

.ant-btn {
  font-weight: 600;
}
/* .ant-btn:disabled {
  opacity: 0.45;
  pointer-events: none;
} */
.ant-btn.ant-btn-info {
  color: white;
  background-color: rgb(0, 184, 217);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.ant-btn.ant-btn-info:hover {
  background-color: rgb(0, 108, 156);
  box-shadow: rgba(0, 184, 217, 0.24) 0px 8px 16px 0px;
}
.ant-btn.primary-outlined {
  color: #54006e !important;
  border: 1px solid !important;
}
.ant-btn.primary-outlined:hover {
  background-color: #f3e5f5 !important;
}
.ant-btn.ant-btn-facebook {
  color: white !important;
  background-color: #1877f2 !important;
}
.ant-btn.ant-btn-facebook:hover {
  background-color: #2e89ff !important;
}
.ant-btn.ant-btn-linkedin {
  color: white !important;
  background-color: #0a66c2 !important;
}
.ant-btn.ant-btn-linkedin:hover {
  background-color: #1e8eff !important;
}
.ant-btn.ant-btn-instagram {
  border: 0;
  color: white !important;
  background: radial-gradient(
    128.57% 128.57% at 10.71% 105.36%,
    #ffcb52 0%,
    #e34677 56.25%,
    #c938ac 100%
  ) !important;
}
.ant-btn.ant-btn-instagram:hover {
  opacity: 0.75;
}
.ant-btn.ant-btn-google {
  color: #3c4043;
  min-width: 125px;
  border: 1px solid #dadce0;
}
.ant-btn.ant-btn-google:hover {
  background-color: #f8f9fa;
  border: 1px solid #d2e3fc;
}

.form-label,
.ant-form-item .ant-form-item-label > label {
  height: auto !important;
  color: #6b0d88;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.ant-table-title {
  padding: 0 !important;
}
span.ant-tag {
  border: 0;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 20px;
}

.ant-spin.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  gap: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  background: white;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.email-template .ant-collapse-item {
  border-radius: 8px !important;
  border: 1px solid transparent;
  margin-bottom: 1rem !important;
  background: #faf5fc !important;
}
.email-template .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 1rem !important;
}
.email-template
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  font-size: 20px !important;
}
.email-template .ant-collapse-item.ant-collapse-item-active {
  background: #ffffff !important;
  border: 1px solid #893da0 !important;
}
.email-template
  .ant-collapse
  > .ant-collapse-item.ant-collapse-item-active
  > .ant-collapse-header
  .ant-collapse-header-text {
  color: #893da0 !important;
}

.ant-collapse-header {
  font-size: 16px;
  font-weight: 600;
  pointer-events: none;
}
.ant-collapse-header .ant-collapse-header-text {
  font-size: inherit;
  pointer-events: all;
  flex: none !important;
}
.ant-collapse-header .ant-collapse-header-text:hover {
  color: #893da0;
}

/* .automation-form .ant-row.ant-form-item-row .ant-form-item-label-left {
  padding-top: 12px;
} */

.ant-notification
  .ant-notification-notice
  .ant-notification-notice-with-icon
  .ant-notification-notice-description::first-letter {
  text-transform: uppercase;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-group-title,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-group-title {
  color: #424242;
  font-weight: bold;
}
.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  overflow: auto;
  max-height: 342px;
}

/* Countdown */
.ant-statistic.wait-countdown .ant-statistic-title {
  font-size: 24px;
  font-weight: 600;
}
.ant-statistic.wait-countdown .ant-statistic-content-value {
  font-size: 36px;
  font-weight: 600;
}

.ant-typography.ant-typography-info {
  color: #893da0;
}

.ant-progress.step-margin .ant-progress-steps-outer {
  gap: 16px;
  justify-content: space-between;
}
.ant-progress.step-margin .ant-progress-steps-item {
  flex: 1;
  margin-inline-end: 0;
}
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
  word-break: break-all;
}
